// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import MKButton from "components/MKButton";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

// @mui material components
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

//data
import cv from "cv"

// Images
import bgImage from "assets/images/banner5.webp";
import jules from "assets/images/id_nb.webp";

function Resume() {

    const socialIcons = (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
      <MKTypography
        component={MuiLink}
        target="_blank"
        href="https://github.com/julesmorel#"
        variant="button"
        p={1}
        textAlign="right"            
      >
        <MKBox component="i" color="text" className="fab fa-github" />
      </MKTypography>
      <MKTypography
        component={MuiLink}
        href="https://linkedin.com/in/jules-morel-73975a39"
        target="_blank"
        variant="button"
        textAlign="right"
        p={1}
      >
        <MKBox component="i" color="text" className="fab fa-linkedin" />
      </MKTypography>
{/*       <MKTypography
        component={MuiLink}
        target="_blank"
        href="https://www.instagram.com/julios_morelos"
        variant="button"
        textAlign="right"
        p={1}
      >
        <MKBox component="i" color="text" className="fab fa-instagram" />
      </MKTypography> */}
      </Grid> 
      );

    const actionsIcons = (
    <>
        <MKButton
            component={MuiLink}
            href={`${global.cv_eng}`}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="small"
            color="secondary"
            sx={{ mr: 1 }}
          >
            <Icon sx={{ fontWeight: "bold", mr: 1 }}>picture_as_pdf</Icon> 
            CV (English)
        </MKButton>
        <MKButton
            component={MuiLink}
            href={`${global.cv_fr}`}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="small"
            color="secondary"
            sx={{ mr: 1 }}
          >
            <Icon sx={{ fontWeight: "bold", mr: 1 }}>picture_as_pdf</Icon> 
            CV (Français)
        </MKButton>
    </>
    ); 

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "80%",
          display: "grid",
          placeItems: "center",
        }}     
      >
        <Container>        
            <MKTypography
              variant="h1"
              color="white"
              mt={6}
              mb={1}
              textAlign="right"
              px={2}
            >
              Resume
            </MKTypography>
        </Container>
      </MKBox>

      <MKBox py={{ xs: 4, sm: 4, lg:6 }} px={{ xs: 2, sm: 2, lg:24 }} mb={4}>     
           <MKBox width="100%" py={4}>
           <Grid container>
              <Grid item xs={12} md={3} xl={3} sx={{ pl: 4 }}>
              <MKBox position="relative" borderRadius="lg">
                <MKBox
                  component="img"
                  src={jules}
                  alt="jules"
                  borderRadius="lg"
                  width="100%"
                  maxWidth="220px"
                  position="relative"
                  zIndex={1}
                />
                <MKBox
                  borderRadius="lg"
                  shadow="xxl"
                  width="100%"
                  maxWidth="200px"
                  height="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${jules})`,
                    transform: "scale(0.96)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MKBox>
              </Grid>
              <Grid item xs={12} md={9} xl={9} sx={{ px: { xs: 4, md: 4 }}}>
              <MKBox>
              <MKTypography variant="h3" sx={{ mt: { xs: 2, md: 0 }}} >
              Jules Morel
              </MKTypography>
              <MKTypography variant="subtitle1" color="text" mt={1}>
                Freelance Developer
              </MKTypography>
              <Stack direction="row" spacing={2} mt={1}>
                {socialIcons}
              </Stack>           
              <MKTypography variant="body2" color="text" mt={1}>
                Geometric Modeling | Machine Learning | Data science      
              </MKTypography>
              <Stack direction="row" spacing={2} mt={4}>
              {actionsIcons}
              </Stack>
              </MKBox>  
              </Grid>
            </Grid> 
        </MKBox>   
      
      <MKBox px={2} pt={4}>
      <MKTypography variant="h2" textAlign="left" my={2}>
        Experience
      </MKTypography>  
      {cv.map((entry, index) => (
        <MKBox key={index} pt={4} pb={2}>
          
          <MKBox display="flex" flexDirection="row" justifyContent="space-between">
            <MKBox display="flex" flexDirection="column" mr={6}>
              <MKTypography variant="h5" color="text" textAlign="left">
                {entry.company}
              </MKTypography>
              <MKTypography variant="body2" color="text" textAlign="left" textTransform="uppercase" mt={1}>
                {entry.title}
              </MKTypography>
              <MKTypography variant="body2" color="text" textAlign="left" mt={1}>
                {entry.description}
              </MKTypography>
            </MKBox>    
            <MKBox display="flex" flexDirection="column" pl={1}>
              <MKTypography variant="body2" color="text" textTransform="uppercase" textAlign="right" display={{ xs: "none", md: "block" }}>
              <LocationOnOutlinedIcon sx={{ mb:-0.2, mr: 1 }}/>{entry.place}
              </MKTypography>
              <MKTypography variant="body2" color="text" textAlign="right" fontWeight="light">
                {entry.date}
              </MKTypography>
            </MKBox>
          </MKBox>  
           
        </MKBox>  
      ))}
      </MKBox> 
      </MKBox>  
      <MKBox pt={0} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Resume;