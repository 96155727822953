const papiers2 = [
    {
        date:"2018",
        authors:"Xinlian Liang, Juha Hyyppä, Jules Morel, …, Yunsheng Wang",
        title:"International benchmarking of terrestrial laser scanning approaches for forest inventories",
        journal:"ISPRS Journal of photogrammetry and remote sensing",
    },
    {
        date:"2015",
        authors:"J.Morel, A. Bac, C. Véga",
        title:"Computation of tree volume from TLS data",
        journal:"Proceedings of Silvilaser, Geospatial Week",
    },
    {
        date:"2015",
        authors:"J. Morel, A. Bac, C. Véga",
        title:"Forest carbon assessment from LiDAR 3D point cloud analysis",
        journal:"Regional Forum on Climate Change, AIT Bangkok",
    },
    {
        date:"2015",
        authors:"C. Véga, U. Vepakomma, J. Morel, J. L. Bader, G. Rajashekar, C. S. Jha, J. Ferêt, C. Proisy, R. Pélissier, V. K. Dadhwal",
        title:"Aboveground biomass estimation of a complex tropical forest in India using LiDAR",
        journal:"Remote Sensing",
    },
    {
        date:"2015",
        authors:"V. Bonhomme, M. Castets, J. Morel, C. Gaucherel",
        title:"Introducing the vectorial Kappa: An index to quantify congruence between vectorial mosaics",
        journal:"Ecological Indicators",
    },
    {
        date:"2014",
        authors:"C. Véga, A. Hamrouni, S. El Mokhtari, J. Morel, J. Bock, J.-P. Renaud, M. Bouvier, S. Durrieu",
        title:"PTrees: A point-based approach to forest tree extraction from LiDAR data",
        journal:" International Journal of Applied Earth Observation and Geoinformation",
    },
    {
        date:"2012",
        authors:"C. Véga, S. Durrieu, J. Morel, T. Allouis",
        title:"A sequential iterative dual-filter for Lidar terrain modeling optimized for complex forested environments",
        journal:"Computers & Geosciences",
    },

];
export default papiers2;