// @mui icons
// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

const footer={
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}      
    </MKTypography>
  ),
};
export default footer;