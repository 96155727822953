const cv = [
    {
        date:"2023",
        place:"Tokyo, Japan",
        company:"Mirukuru",
        title:"R&D Engineer",
        description:"3D point cloud processing and rendering.",
    },
    {
        date:"2023",
        place:"Toulouse, France",
        company:"SKELENN",
        title:"Freelance Developer",
        description:"Development of an iOS app designed for the 3D scanning of body parts.",
    },
    {
        date:"2022",
        place:"Toulouse, France",
        company:"Michelin",
        title:"Freelance Developer",
        description:"Implementation of bidirectional Dijkstra's Shortest Path Algorithm.",
    },
    {
        date:"2022",
        place:"Toulouse, France",
        company:"National Research Institute for Agriculture, Food and the Environment (INRAE)",
        title:"Freelance Developer",
        description:"Automatic processing of terrestrial LiDAR data using deep learning.",
    },
    {
        date:"2022",
        place:"Toulouse, France",
        company:"Sanofi Pasteur",
        title:"Freelance Developer",
        description:"Computation of the invasive pneumococcal disease burden for defined vaccine compositions.",
    },
    {
        date:"2021",
        place:"Toulouse, France",
        company:"Ecole Française Dentaire",
        title:"Freelance Developer",
        description:"Development of an automatic generator of examination questions.",
    },
    {
        date:"from 2018 to 2020",
        place:"Tokyo, Japan",
        company:"Kanai Laboratory, The University of Tokyo",
        title:"Postdoctoral Researcher",
        description:"Geometric modeling and growth prediction of trees using 3D point clouds acquired in forests.",
    },
    {
        date:"2018",
        place:"Edinburgh, UK",
        company:"Carbomap LTD.",
        title:"Freelance Developer",
        description:"Development of point cloud processing algorithms using GPGPU.",
    },
    {
        date:"2018",
        place:"Puducherry, India",
        company:"French National Research Institute for Sustainable Development (IRD)",
        title:"Freelance Developer",
        description:"Development of a progressive web app.",
    },
    {
        date:"2017",
        place:"Montpellier, France",
        company:"Research Unit for Botany and Modeling of Plant Architecture and Vegetation (AMAP)",
        title:"Postdoctoral Researcher",
        description:"Algorithms development in the platform Computree.",
    },
    {
        date:"from 2013 to 2017",
        place:"Marseille, France",
        company:"Laboratoire d'Informatique et Systèmes, Aix Marseille University",
        title:"PhD Student",
        description:"Surface Reconstruction Based on Forest Terrestrial LiDAR Data.",
    },
];

export default cv;    