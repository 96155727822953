import img1 from "assets/images/mnt.webp";
import img2 from "assets/images/dtm.webp";
import img3 from "assets/images/deep.webp";
import img4 from "assets/images/csrbf_blender.webp";
import img5 from "assets/images/classicvr3.webp";

import './global.js';

const papiers = [
    {
        date:"2020",
        authors:"J. Morel, A. Bac, and T. Kanai",
        title:"Digital terrain model from UAV photogrammetric data",
        journal:"Smart Tools and Application in Graphics",
        description:"This paper presents a method designed to finely approximate ground surfaces from UAV photogrammetric point clouds by relying on statistical filters to separate vegetation from potential ground points, dividing the whole plot in similar complexity sub-plots through an optimized tilling, and filling holes by blending multiple local approximations through the partition of unity principle. Experiments on very different terrain topology show that our approach leads to significant improvement over the state-of-the-art method.",
        image:img1,
        link:"https://graphics.c.u-tokyo.ac.jp/archives/stag2020jules.pdf",
    },
    {
        date:"2020",
        authors:"J. Morel, A. Bac, and T. Kanai",
        title:"High accuracy terrain reconstruction from point clouds using implicit deformable model",
        journal:"The Visual Computer",
        description:"This paper presents a method designed to finely approximate ground surfaces by relying on deep learning to separate vegetation from potential ground points, filling holes by blending multiple local approximations through the partition of unity principle, then improving the accuracy of the reconstructed surfaces by pushing the surface towards the data points through an iterative convection model.",
        image:img2,
        link:"https://rdcu.be/cS3lo",
    },
    {
        date:"2020",
        authors:"J. Morel, A. Bac, and T. Kanai",
        title:"Segmentation of unbalanced and in-homogeneous point clouds and its application to 3D scanned trees",
        journal:"Meshfree, Lecture Notes in Computer Sciences",
        description:"Segmentation of 3D point clouds is still an open issue in the case of unbalanced and in-homogeneous data-sets. In the application context of the modeling of botanical trees, a fundamental challenge consists in separating the leaves from the wood. Based on deep learning and a class decision process, we propose an innovative method designed to separate leaf points from wood points in terrestrial LiDAR point clouds of trees. Although simple, our approach learns trees characteristic point patterns efficiently and robustly. To train our 3D deep learning model, we constructed a 3D labeled point cloud data-set of different tree species. Experiments show that our 3D deep representation together with our geometric approach lead to significant improvement over the state-of-the-art methods in segmentation task..",
        image:img3,
        link:`${global.deep}`,
    },
    {
        date:"2018",
        authors:"J. Morel, A. Bac, C. Véga",
        title:"Surface reconstruction of incomplete datasets: a novel Poisson surface approach based on CSRBF",
        journal:"Computer & Graphics",
        description:"This paper introduces a novel surface reconstruction method based on unorganized point clouds, which focuses on offering complete and closed mesh models of partially sampled object surfaces. To accomplish this task, our approach builds upon a known a priori model that coarsely describes the scanned object to guide the modeling of the shape. In the region of space visible to the scanner, we retrieve the surface by following the resolution of a Poisson problem. In the occluded region of space, we consider the a priori model as a sufficiently accurate descriptor of the shape. Both models are then blended to obtain a closed model.",
        image:img4,
        link:`${global.csrbf}`,
    },
    {
        date:"2017",
        authors:"J. Morel",
        title:"An Android Application to visualize point clouds and meshes in VR",
        journal:"Proceedings of the 11th International Conference on Computer Graphics, Visualization, Computer Vision and Image Processing",
        description:"This paper presents a review of well-known rendering techniques and their adaptation to the features of OpenGL ES 2.0 to develop an Android application dedicated to the visualization of surface meshes and point clouds in virtual reality. Using the headtracking sensors of the smartphone, a generic Bluetooth controller and a virtual reality headset, this application has proven to be a powerful tool to investigate and explore 3D point clouds and meshed surfaces as a VR environment.",
        image:img5,
        link:"https://hal.archives-ouvertes.fr/hal-01570952/document",
    },
];

export default papiers;   