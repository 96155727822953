/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function RaisedBlogCard({ image, category, title, descriptions, actions, date }) {

  return (
    <>
      <MKBox position="relative" borderRadius="lg" mx={0} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="lg"
          width="100%"
          height="100%"
          position="absolute"
          left={1}
          top={1}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.95)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox px={1} py={2}>
      {category && (
        <MKBox mb={1}>
          <MKTypography
            variant="caption"
            color={category.color}
            textTransform="uppercase"
            fontWeight="medium"
            textGradient
            sx={{ display: "block" }}
          >
            {category.label}
          </MKTypography>
          </MKBox>
        )}
        <MKTypography display="inline" variant="h4">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={2}>
        {descriptions.map((description, index) => (    
          <MKBox mb={1}> 
          <MKTypography variant="body2" component="p" color="text" align="justify" key={index}>
            {description.text}
          </MKTypography>
          </MKBox>
        ))}  
        </MKBox>

        {actions.map((action, index) => (  
        <span key={index} >   
        {action.type === "external" ? (
          <MKButton
            component={MuiLink}
            href={action.route}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="small"
            color={action.color ? action.color : "dark"}
            sx={{ mr: 1 }}
          >
            <Icon sx={{ fontWeight: "bold", mr: 1 }}>{action.icon}</Icon> 
            {action.label}
          </MKButton>
        ) : (
          <MKButton
            component={Link}
            to={action.route}
            variant="outlined"
            size="small"
            color={action.color ? action.color : "dark"}
            sx={{ mr: 1 }}
          >
            <Icon sx={{ fontWeight: "bold", mr: 1 }}>{action.icon}</Icon>      
            {action.label}
          </MKButton>
        )}
        </span>
        ))}
        <MKBox mt={1}>
          <MKTypography variant="caption" color="text" fontWeight="light" textTransform="uppercase">
              {date}
          </MKTypography>
        </MKBox>
      </MKBox>
    </>
  );
}

// Typechecking props for the RaisedBlogCard
RaisedBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default RaisedBlogCard;
